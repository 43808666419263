import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  return {
    modifiers: {
      capitalizeAllLetters: (str) =>
        str.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase()),
    },
  };
});
